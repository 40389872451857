<template>
  <div id="app">
    <div class="">
      <div id="bar" class="mb-5">
        <h1 class="mb-5">Email Template</h1>
        <v-btn @click="saveTemplateAction" v-if="!loading">Save Template</v-btn>
        <v-btn v-if="loading" loading></v-btn>
      </div>

      <v-row>
        <v-col md="6">
          <v-label>Template Name : </v-label>
          <v-text-field
            class="mt-2"
            v-model="title"
            placeholder="Template Name"
            outlined
          ></v-text-field>
        </v-col>
        <v-col md="6">
          <v-label>Template For : </v-label>
          <v-select
            class="mt-2"
            :items="typeofs"
            v-model="usedFor"
            placeholder="Template For"
            outlined
          ></v-select>
        </v-col>
      </v-row>

      <EmailEditor
        :appearance="appearance"
        :min-height="minHeight"
        :project-id="projectId"
        :locale="locale"
        :tools="tools"
        :options="options"
        ref="emailEditor"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
      />
    </div>
    <div v-html="html"></div>
  </div>
</template>

<script>
import { EmailEditor } from "vue-email-editor";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Emails",
  components: {
    EmailEditor,
  },
  data: () => ({
    minHeight: "1000px",
    locale: "en",
    title: "",
    typeofs: ["Initial", "Resend", "Reoffer", "Available", "Team"],
    projectId: 0,
    tools: {
      // disable image tool
      image: {
        enabled: true,
      },
    },
    options: {},
    appearance: {
      theme: "dark",
      panels: {
        tools: {
          dock: "right",
        },
      },
    },
    html: "hey",
    saveTemplate: undefined,
    saveHTMLTemplate: undefined,
    loading: false,
    _id: undefined,
    usedFor:'Initial'
  }),
  methods: {
    ...mapActions(["createEmailTemplate", "singleEmailTemplate"]),
    editorLoaded() {
      console.log("editorLoaded");
      this.$refs.emailEditor.editor.loadDesign();
    },
    editorReady() {
      console.log("editorReady");
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        this.saveTemplate = design;
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.saveHTMLTemplate = data.html;
      });
    },
    async saveTemplateAction() {
      this.saveDesign();
      this.exportHtml();
      if (this.saveTemplate != undefined) {
        this.loading = true;
        let data = {};
        data.title = this.title;
        data.emailTemplate = this.saveTemplate;
        data.templateGenerated = this.saveHTMLTemplate;
        data.usedFor = this.usedFor;
        if (this._id != undefined) {
          data._id = this._id;
        }
        await this.createEmailTemplate(data);
        this.loading = false;
        this.saveTemplate = undefined;
        this.saveHTMLTemplate = undefined;
      }
    },
  },
  computed: {
    ...mapGetters(["allEmailTemplate"]),
  },
  async created() {
    if (this.$route.params.id != undefined) {
      await this.singleEmailTemplate(this.$route.params.id);
      this.$refs.emailEditor.editor.loadDesign(
        this.allEmailTemplate.results.emailTemplate
      );
      this.title = this.allEmailTemplate.results.title;
      this._id = this.allEmailTemplate.results._id;
      this.usedFor = this.allEmailTemplate.results.usedFor;
    }
  },
};
</script>

<style lang="scss" scoped>
#bar {
  .v-btn {
    color: #fff;
  }
}
</style>